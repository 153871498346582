@import "styles/utils.module";
@import "styles/color.module";

.midCategoryWrapper {
  @extend .flexRow;
  padding-left: 12px;
  padding-right: 12px;
  position: sticky;
  left: 0;
  top: $topBarHeightMobile;
  z-index: 1000;
  height: $mobilStoreNavBarHeight;
  background-color: white;
  white-space: nowrap;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.midCategory {
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  color: $typo2;
  padding: 13px 8px;
}

.activeCategory {
  color: $brand;
}

.border {
  border-top: 1px solid $border;
}

.cell {
  width: 100%;
  @extend .justifyContentCenter;
  @extend .alignItemsCenter;
  font-size: 13px;
}
